import React from 'react'
import useCollapsedAirRouteCollections from '../../hooks/useCollapsedAirRouteCollections'
import FlightSearchPageResultCardDesktop from './FlightSearchPageResultCardDesktop'

const FlightSearchPageResultCardDesktopWithState = ({
    airRouteCollections,
    ...props
}) => {
    const {
        onExpandCollections,
        collapsedAirRoutesState,
        collapsedAirRouteCollections,
    } = useCollapsedAirRouteCollections(airRouteCollections)

    return (
        <FlightSearchPageResultCardDesktop
            onExpand={onExpandCollections}
            collapsedState={collapsedAirRoutesState}
            airRouteCollections={collapsedAirRouteCollections}
            {...props}
        />
    )
}

export default FlightSearchPageResultCardDesktopWithState
