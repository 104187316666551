import React from 'react'
import FlightOverviewMobile from './FlightOverviewMobile'
import FlightOverviewDesktop from './FlightOverviewDesktop'

const FlightOverview = ({
    ...props
}) => (
    <>
        <FlightOverviewMobile {...props} />
        <FlightOverviewDesktop {...props} />
    </>
)

export default FlightOverview
