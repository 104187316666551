import React from 'react'
import { millisecondsToFlightDurationString } from '@connections/utils'
import Stops from './Stops'
import FlightOverviewTimingMobile from './FlightOverviewTimingMobile'

const FlightOverviewBodyMobile = ({
    stops,
    duration,
    originCity,
    arrivalTime,
    departureTime,
    destinationCity,
}) => (
    <div className="flex gap-x-4">
        <FlightOverviewTimingMobile
            stops={stops}
            arrivalTime={arrivalTime}
            departureTime={departureTime}
        />
        <div className="flex flex-col items-start gap-3 w-full">
            <div className="flex w-full align-top gap-2">
                <p className="text-gray-600 flex flex-wrap gap-x-1">
                    <span>{originCity.name}</span>
                    <span>{originCity.nameExtension && `(${originCity.nameExtension})`}</span>
                </p>
            </div>
            <div className="flex items-center w-full gap-x-4 gap-y-2 flex-wrap text-sm text-gray-600">
                <div className="flex gap-4">
                    <Stops count={stops} />
                    {duration !== 0 && (
                        <span className="block">
                            {millisecondsToFlightDurationString(duration)}
                        </span>
                    )}
                </div>
            </div>
            <p className="text-gray-600 flex flex-wrap gap-x-1">
                <span>{destinationCity.name}</span>
                <span>{destinationCity.nameExtension && `(${destinationCity.nameExtension})`}</span>
            </p>
        </div>
    </div>
)

export default FlightOverviewBodyMobile
